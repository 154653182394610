import { h } from "@stencil/core";
import { ElementIdGenerator } from "../../classes/element-id-handling";
export const ErrorPopup = (props) => {
    var _a;
    return (h("dx-popup", { type: "error", show: ((_a = props.text) === null || _a === void 0 ? void 0 : _a.length) > 0 }, h("dx-text", null, props.text)));
};
export const ErrorMessage = (props, content) => {
    const messageDefined = Array.isArray(props.message)
        ? props.message.filter((message) => !!message).length > 0
        : !!props.message;
    return messageDefined ? (h("dx-error-message", Object.assign({ id: ElementIdGenerator.createId(props.self, "error-message") }, props.custom, { onForceUpdateOnChange: props.forceUpdateOnChange }), h("ul", { style: {
            "padding": "0",
            "margin": "0",
            "list-style-type": "none"
        } }, Array.isArray(props.message)
        ? props.message.filter((message) => !!message).map((message) => h("li", null, message))
        : props.message, h("li", null, content)))) : (h("slot", { name: "error-message", onSlotchange: props.handleSlotChange }));
};
